import React, { useEffect, useState } from 'react';
import ReadingChip from '../../Application/Components/ReadingChip/index.js'
import QuickChip from '../../Application/Components/ReadingChip/quickchip.js'
import { formatDate } from '../../Shared/Components/functions.js'
import EmptyStateReadings from './EmptyState';
import { useSocialSerial } from './useSocialSerial';

export default function ReadingsBySerial(props) {
    const resultsPerPage = 10;

    // pagination
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedClientReadings()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    // set up firestore query
    const readings = useSocialSerial(props.serialno, referenceItem, include, resultsPerPage);
    const currentLastVisibleItem = (readings !== undefined) ? readings.docs[readings.size - 1] : null;

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, readings.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (readings !== undefined && readings.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (readings !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, readings]);

    return (
        <div>
            {   // empty state
                readings.docs.length === 0 && <EmptyStateReadings />
            }

            <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-md">
                {readings === undefined && "Loading"}
                <ul className="divide-y divide-gray-200">
                    {readings !== undefined && readings.docs.map((reading) => (

                        <li key={reading.id}>
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                            <p className="mt-2 flex items-center text-sm text-gray-900">
                                                <span className="font-bold truncate">{formatDate(reading.data().created.toDate())}
                                                    {(reading.data().handset) && <span className='font-normal'><br />{reading.data().handset}</span>}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="hidden md:block">
                                            <div>
                                                <p className="text-sm text-gray-900">
                                                    {reading.data().placemark && reading.data().placemark.locality && <span className='font-bold'>{reading.data().placemark.locality}</span>}
                                                    {reading.data().placemark && reading.data().placemark.locality && reading.data().placemark.administrativearea && <br />}
                                                    {reading.data().placemark && reading.data().placemark.administrativearea && reading.data().placemark.administrativearea}

                                                    {(!reading.data().placemark && reading.data().location) && <span>GPS coordinates present</span>}

                                                    {(!reading.data().location) && "Location not recorded"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {reading.data().quickmode !== undefined && reading.data().quickresult !== undefined && reading.data().quickmode === true && <QuickChip result={reading.data().quickresult} /> }
                                {(reading.data().quickmode === undefined || (reading.data().quickmode !== undefined && reading.data().quickmode === false)) && <ReadingChip value={reading.data().baclevel} unit={reading.data().bacscale} /> }
                            </div>
                        </li>

                    ))
                    }
                </ul>
            </div>

            {readings.docs.length > 0 && /* pagination start */
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }

        </div>
    )
}