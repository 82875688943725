export default function QuickChip({ result }) {
    const greenStyleFg = 'text-green-600';
    const redStyleFg = 'text-red-700';

    const greenStyleBg = 'bg-green-100';
    const redStyleBg = 'bg-red-100';

    // defaults for zero readings
    var thisStyleFg = greenStyleFg;
    var thisStyleBg = greenStyleBg;

    // if not a zero reading then set new colours
    if (result.toLowerCase() === "pass") {
        thisStyleFg = greenStyleFg;
        thisStyleBg = greenStyleBg;
    } else {
        thisStyleFg = redStyleFg;
        thisStyleBg = redStyleBg;
    }

    const capitalize = (s) => {
        return s && String(s[0]).toUpperCase() + String(s).slice(1);    
    }

    return (
        <div className="flex overflow-hidden -space-x-1 mr-3">
            <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${thisStyleFg} ${thisStyleBg}`}>
                {capitalize(result)}
            </span>
        </div>
    )
}